import React, { useMemo } from 'react';
import intl from 'react-intl-universal';

import styles from './BindWrapper.less';
import banner from '../../assets/bind/banner.svg';
import bannerTitleEn from '../../assets/bind/banner_title_en.png';
import bannerTitleFr from '../../assets/bind/banner_title_fr.png';

const BindWrapper = ({ children }) => {
  const bannerTitle = useMemo(() => {
    const lang = intl.getInitOptions().currentLocale;
    return lang === 'fr-FR' ? bannerTitleFr : bannerTitleEn;
  }, []);
  return (
    <div className={styles.linkStoreWrap}>
      <div className={styles.linkbox}>{children}</div>
      {/* 后续bind流程统一在这里加banner */}
      <div className={styles.bannerArea}>
        <img className={styles.bannerTitle} src={bannerTitle} />
        {/* <img className={styles.bannerImg} src={banner} /> */}
        <div className={styles.imgContainer}>
          <img className={styles.bannerImg} src={banner} />
        </div>
      </div>
    </div>
  );
};

export default React.memo(BindWrapper);
