import React, { useEffect, useMemo } from 'react';
import { withRouter } from 'dva/router';
import { connect } from 'dva';
import intl from 'react-intl-universal';

import { checkUserForInstallExt } from 'common/checkUserForInstallExt';
import styles from './RegisterProcess.less';

// 检测用户是否在注册流程中 以是否看过新手引导为准
const inRegisterProcess = userInfo => {
  const { status = '' } = userInfo;
  const res = status.toString().substring(1, 2) === '0';
  return res;
};

const activeMap = {
  '/bind/link-store': 0,
  '/bind/ae': 2,
  '/bind/install_plugin': 3
};

const ProcessItem = ({ title, num, isLatest, isActive, isFinished }) => {
  return (
    <div
      className={`${styles.processItem} ${isActive ? styles.active : ''} ${
        isFinished ? styles.finished : ''
      }`}
    >
      <div className={styles.num}>{num}</div>
      <div className={styles.title}>{title}</div>
      {!isLatest ? <div className={styles.line} /> : null}
    </div>
  );
};

const RegisterProcess = ({ userInfo, location }) => {
  // 若不是注册流程中则不显示进度条
  const showProcess = useMemo(() => {
    if (!userInfo) {
      return false;
    }
    return inRegisterProcess(userInfo);
  }, [userInfo]);

  const installPluginTest = useMemo(() => {
    return checkUserForInstallExt() && showProcess;
  }, [showProcess]);

  const items = useMemo(() => {
    let cacheItems = [
      intl.get('linkStore.process.link_store'),
      intl.get('linkStore.process.choose_plan'),
      intl.get('linkStore.process.link_ae'),
      intl.get('linkStore.process.install_plugin')
    ];
    // 若未命中灰度或浏览器不支持装插件 则只显示三步进度条
    if (!installPluginTest) {
      cacheItems = cacheItems.slice(0, 3);
    }
    return cacheItems;
  }, [installPluginTest]);

  useEffect(() => {
    if (installPluginTest) {
      window.dataLayer.push({
        event: 'forceInstallTest',
        payload: 'A'
      });
    }
  }, [installPluginTest]);

  const currentActive = activeMap[location.pathname];
  return showProcess ? (
    <div className={styles.processContainer}>
      {items.map((item, index) => {
        return (
          <ProcessItem
            key={item}
            isLatest={items.length === index + 1}
            title={item}
            num={index + 1}
            isActive={index === currentActive}
            isFinished={index < currentActive}
          />
        );
      })}
    </div>
  ) : null;
};

export default connect(({ login }) => {
  return {
    userInfo: login.userInfo
  };
})(withRouter(React.memo(RegisterProcess)));
