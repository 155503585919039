import React from 'react';
import { connect } from 'dva';
import { Button, Spin, message, Modal, Tooltip, Alert } from 'antd';

import intl from 'react-intl-universal';

import { routerRedux } from 'dva/router';

import styles from './BindAE.less';
import logo from 'assets/svg/logo.svg';
import RegisterProcess from './RegisterProcess';
import BindWrapper from './BindWrapper';
import NewBindWrapper from './NewBindWrapper';
import { CommonDataCollect } from 'utils/utils';
import Media from 'react-media';
import MobileHeader from 'routes/Pricing/Mobile/Header';
import rightImage from 'assets/linkAe.png';
import { Post } from 'utils/request';

@connect(({ loading, global }) => ({
  loading: loading.models.topBtn,
  AEInfo: global.AEInfo
}))
class BindAE extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showBindAeLoading: false
    };
  }

  componentDidMount() {
    const { loading, AEInfo, dispatch, location } = this.props;

    window.dtag.set({
      event: 'bind_ae',
      module: 'ae',
      action: 'initialize',
      action_description: '',
      custom_data: {}
    });

    // 绑定过ae
    if (AEInfo?.status == 1) {
      dispatch(routerRedux.push('/'));
    }
  }

  // 绑ae
  handleBindAE = () => {
    this.setState({
      showBindAeLoading: true
    });
    window.dtag.set({
      event: 'bind_ae',
      module: 'ae',
      action: 'bind_trigger',
      action_description: '',
      custom_data: {}
    });
    const { AEInfo } = this.props;

    window.dataLayer.push({
      event: 'bindAEEvent',
      type: 'linkToAE',
      position: 'bindAE'
    });
    // 针对Oberlo用户进行打点
    if (sessionStorage.getItem('noMigra') == false) {
      CommonDataCollect({
        action: 'migration_bind_ae',
        scene: 0
      });
    }
    this.props.dispatch({
      type: 'topBtn/aliauth',
      payload: {
        cancelMessage: true,
        callback: d => {
          try {
            this.setState({
              showBindAeLoading: false
            });
            if (!d?.code) {
              message.error(
                'Request timeout, please try again. If it keeps failing, please contact us.'
              );
              return;
            }
            if (d.code === 2000 && d.data?.url) {
              window.location = d.data.url;
            } else if (d.code === 4090) {
              window.location = '/app/';
            } else {
              message.error(
                d?.msg ||
                  'Request timeout, please try again. If it keeps failing, please contact us.'
              );
            }
          } catch (e) {
            console.error('绑定ae失败', e);
            message.error(
              'Request timeout, please try again. If it keeps failing, please contact us.'
            );
          }
        }
      }
    });
  };

  handleApplyWhitelist = () => {
    window.dataLayer.push({
      event: 'bindAEEvent',
      type: 'Apply for whitelist'
    });

    window.location.href =
      'https://home.aliexpress.com/dropshipper/join_drop_shipper.htm';
  };

  handleLogout = () => {
    window.dtag.set({
      event: 'bind_ae',
      module: 'ae',
      action: 'log_out',
      action_description: '',
      custom_data: {}
    });
    if (window.localStorage.getItem('online_notice_ioss')) {
      window.localStorage.removeItem('online_notice_ioss');
    }
    window.dataLayer.push({
      event: 'bindAEEvent',
      type: 'logout',
      position: 'bindAE'
    });

    window.localStorage.removeItem('dser-pro-authority');
    window.localStorage.removeItem('store');
    window.localStorage.removeItem('storeId');
    window.location.href = '/app/login';
  };

  handleSwitchAE = () => {
    // eslint-disable-next-line
    const that = this;

    Modal.confirm({
      title: <p>{intl.getHTML('setting.account.switch_ae')}</p>,
      okText: intl.get('public.ok'),
      cancelText: intl.get('public.cancel'),
      loading: false,
      onOk: () => {
        that.props.dispatch({
          type: 'dropshippersetting/unBindAli',
          payload: {
            cancelMessage: true,
            callback: d => {
              if (d.code === 2000 || d.code === 2010) {
                that.props.dispatch({
                  type: 'dropshippersetting/reconnectAli',
                  payload: {
                    cancelMessage: true,
                    callback: res => {
                      if (res.code === 2000) {
                        window.location.href = res.data.url;
                      }
                    }
                  }
                });
              }
            }
          }
        });
      }
    });
  };

  handleEnterDsers = async () => {
    window.dtag.set({
      event: 'bind_ae',
      module: 'ae',
      action: 'enter_into_dsers',
      action_description: '',
      custom_data: {}
    });
    const res = await Post('/auth/ali/flag?status=1', {});
    if (res.code === 2000) {
      window.location.href = '/app/';
    }
  };

  render() {
    const { loading, AEInfo, isMobile } = this.props;

    if (AEInfo === undefined) {
      return (
        <Spin size="large" style={{ width: '100%', margin: '180px auto' }} />
      );
    }

    return (
      <>
        {isMobile ? (
          <div className={styles.container}>
            <MobileHeader />
            <div className={styles.mobilecontent}>
              <div className={styles.mobiletitle}>
                Step 3. {intl.get('linkStore.linkSt')}
              </div>
              <div className={styles.mobiledesck}>
                {intl.get('linkStore.bindAeTitle')}{' '}
                <span> ({intl.get('linkStore.required')})</span>
              </div>
              <div className={styles.mobilebtnLine}>
                {!AEInfo ||
                (AEInfo && (AEInfo.status === 2 || AEInfo.status === 3)) ? (
                  <div
                    className={styles.mobilebindAre}
                    loading={loading}
                    onClick={this.handleBindAE}
                    style={{ marginRight: 32, marginBottom: 32 }}
                  >
                    <img src={require('../../assets/newBing/are.svg')}></img>
                  </div>
                ) : null}

                {!AEInfo ||
                (AEInfo && (AEInfo.status === 2 || AEInfo.status === 3)) ? (
                  <div className={styles.mobileknowMore}>
                    <div>
                      <a
                        href={intl.get('linkStore.bind_ae_tip_url')}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {intl.get('linkStore.bind_ae_tip_1')}
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.aliexpress.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {intl.get('linkStore.bind_ae_tip_2')}
                      </a>
                    </div>
                    <div>
                      <a onClick={this.handleLogout}>{'Log out >>'}</a>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <NewBindWrapper bind="ae">
            <div className={styles.content}>
              {/* <RegisterProcess /> */}
              {/* <div className={styles.field}>
            <p className={styles.fieldTitle}>
              {AEInfo && AEInfo.status === 2
                ? intl.get('top.bind_ae_outdated_intro')
                : intl.get('top.bind_ae_intro')}3333
            </p>
          </div> */}
              <div className={styles.title}>
                Step 3. {intl.get('linkStore.linkSt')}
              </div>
              <div className={styles.desck}>
                {intl.get('linkStore.bindAeTitle')}
              </div>

              <Alert
                message={
                  <div className={styles.alertCenter}>
                    <div className={styles.materialIcons}>
                      <i class="material-icons">info_outline</i>
                    </div>
                    <div className={styles.text}>
                      AliExpress is making adjustments to the account. If you
                      encounter any issues while registering an AliExpress
                      account, please contact us and we will help you resolve
                      the problem.
                    </div>
                  </div>
                }
                type="info"
              />

              <div className={styles.btnLine}>
                {/* {AEInfo || (AEInfo && AEInfo.status === 2 && AEInfo.status === 3) ? (
              <div
                className={styles.bindAre}
                loading={loading}
                onClick={this.handleBindAE}
                style={{ marginRight: 32,marginBottom:32 }}
              >
                <img src={require('../../assets/newBing/are.svg')}></img>
              </div>
            ) : null} */}

                {!AEInfo ||
                (AEInfo && (AEInfo.status === 2 || AEInfo.status === 3)) ? (
                  <>
                    <div
                      className={styles.bindAre}
                      loading={loading}
                      onClick={this.handleBindAE}
                      style={{ marginRight: 32 }}
                    >
                      {this.state.showBindAeLoading && (
                        <div className={styles.bindAreMask}>
                          <Spin
                            style={{ width: '100%', margin: '55px auto' }}
                          />
                        </div>
                      )}
                      <img src={require('../../assets/newBing/are.svg')}></img>
                    </div>
                    <div className={styles.bindAreTip}>
                      {intl.get('linkStore.linkSt')}
                    </div>
                  </>
                ) : null}

                {window.TMALL_USER || window.CAINIAO_USER ? (
                  <div className={styles.noBindAe}>
                    <div className={styles.noBindAeTitle}>
                      {intl.get('linkStore.enterDsersTitle')}
                      <Tooltip title={intl.get('linkStore.enterDsersTooltip')}>
                        <span>
                          <i
                            class="material-icons notranslate"
                            style={{ fontSize: 20, verticalAlign: 'bottom' }}
                          >
                            help_outline
                          </i>
                        </span>
                      </Tooltip>
                    </div>
                    <span onClick={this.handleEnterDsers}>
                      {intl.get('linkStore.clickEnter')}
                    </span>
                  </div>
                ) : null}

                {/* {AEInfo && AEInfo.status === 1 ? (
              <>
                <div
                  className={styles.bindAre}
                  loading={loading}
                  onClick={this.handleApplyWhitelist}
                  style={{ marginRight: 32 }}
                >
                  <img src={require('../../assets/newBing/are.svg')}></img>
                </div>

                <div
                  className={styles.bindAre}
                  onClick={this.handleSwitchAE}
                  style={{ marginRight: 32 }}
                >
                  {intl.get('setting.account.switch')} AliExpress
                </div>
              </>
            ) : null} */}
                {!AEInfo ||
                (AEInfo && (AEInfo.status === 2 || AEInfo.status === 3)) ? (
                  <ul className={styles.knowMore}>
                    <li>
                      <a
                        href={intl.get('linkStore.bind_ae_tip_url')}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {intl.get('linkStore.bind_ae_tip_1')}
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.aliexpress.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {intl.get('linkStore.bind_ae_tip_2')}
                      </a>
                    </li>
                    <li>
                      <a className="logout" onClick={this.handleLogout}>
                        {intl.get('onBoarding.logout')} >>
                      </a>
                    </li>
                  </ul>
                ) : null}

                {/* <Button type="default" onClick={this.handleLogout}>
              {intl.get('public.log_out')}
            </Button> */}
              </div>
              <img className={styles.rightImg} src={rightImage}></img>
            </div>
          </NewBindWrapper>
        )}
      </>
    );
  }
}

export default props => (
  <Media
    queries={{
      small: '(max-width: 599px)'
    }}
  >
    {matches => <BindAE {...props} isMobile={matches.small} />}
  </Media>
);
