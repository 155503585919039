import React,{ useEffect } from 'react';
import SelectLanguage from 'components/GlobalHeader/SelectLanguage';

import './Header.css';

const Header = () => {
  return <div className="SelectPlatFromHeader">
    <div className="SelectPlatFromHeaderBox">
      <div className="SelectLeft">
        <a href="/app/"><img className="logo" src={require('../../assets/new_login_ui/logo.png')}></img></a>
      </div>
      <div className="SelectRigth">
        <div className="lang">
          <SelectLanguage cusClass='loginSelect' itemClass='itemStyle' />
        </div>
        <div className="author">
          {JSON.parse(localStorage.getItem('store'))?.user_name?.substr(0,1)}
        </div>
      </div>
    </div>
  </div>
}

export default Header
