import React,{ useEffect } from 'react';

import './Header.css';

const Header = () => {
  return <div className="SelectPlatFromHeader">
    <div className="SelectPlatFromHeaderBox">
      <div className="SelectLeft">
        <a href="/app/"><img className="logo" src={require('assets/new_login_ui/logo.png')}></img></a>
      </div>
    </div>
  </div>
}

export default Header
