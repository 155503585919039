import React from 'react';
import './SelectPlatform.css';
import Header from './Header'
import intl from 'react-intl-universal';
/**
 *
 *  UI改版需求导致，暂时这么写，有时间再改。
 *
 * @returns
 */

const data = {
  'platform':{
    title:intl.get('select_platform.title'),
    desc:`Enjoy simple and fast access to thousands of products from AliExpress to the world`,
    imgList: require('../../assets/newBing/LinkStore.png')
  },
  'linkStore':{
    title:`Link Your Store`,
    desc:`Enjoy simple and fast access to thousands of products from AliExpress to the world`,
    imgList:require('../../assets/newBing/LinkYourStore.png')
  },
  'pricing':{
    title:`Choose a plan for DSers`,
    desc:`Enjoy simple and fast access to thousands of products from AliExpress to the world`,
    imgList:require('../../assets/newBing/Plan.png')
  },
  'ae':{
    title:intl.get('onBoarding.linkAeTitle'),
    desc:`Enjoy simple and fast access to thousands of products from AliExpress to the world`,
    imgList:require('../../assets/newBing/LinkAE.png')
  }
}

class NewBindWrapper extends React.Component{

  render(){
    const {children, bind} = this.props
    return <>
      <div className={bind === 'pricing' ? null : "PlatFormlayout"}>
        <Header />
        <div className="PlatFormcontent" style={bind === 'pricing' ? {display:'none'} : null}>
            <div className="newContainer">
              <p className="PlatFormcontentTitle">{data[bind]?.title}</p>
            </div>
        </div>
        {children}
      </div>
    </>
  }
}


export default NewBindWrapper
